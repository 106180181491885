/*
* 代替视图，与mutation进行交互，根据用户需求通过hotelApi工具包请求后端数据，通过mutations更新state中的状态
* */
import * as types from "./mutationsTypes"
import accountApi from "../../../api/accountApi";
// import { loginSuccess } from "../../../zsampledata/loginSuccess";

const actions = {

    loginActions({commit}, payload){
        accountApi.login(payload.formData).then(res =>{
            let loginRS = res.data;
            if(loginRS.id !== undefined && loginRS.id != ''){
                loginRS.success = true
            }
            if(!loginRS.success){
                let title = 'Sorry!'
                let errorMsg = ''
                try{
                    errorMsg = loginRS.errors.errors[0].stringValue
                }catch{
                    errorMsg = "Username or password error"
                }
                loginRS.errorTitle = title
                loginRS.errorMsg = errorMsg    
            }
            commit(types.UPDATE_LOGINRS, {
                loginRS: loginRS,
            })
        }).catch(error => {
            let errorRS = {}
            let title = 'Sorry!'
            let errorMsg = ''
            if(error.response){
                try{
                    errorMsg = error.response.data.errors.errors[0].stringValue
                }catch{
                    console.info(error.response)
                    errorMsg = "Some reponse errors"
                }
                
            }else if(error.request){
                console.info(error.request)
                errorMsg = "Some request errors"
            }else{
                console.info(error)
                errorMsg = "Some data errors"
            }
            errorRS.errorTitle = title
            errorRS.errorMsg = errorMsg
            errorRS.success = false
            commit(types.UPDATE_LOGINRS, {
                loginRS: errorRS
            })
        })
    },
	
	updateLoginInfo({commit}, payload){
		commit(types.UPDATE_LOGINRS, {
			loginRS: payload.loginRS,
		})
	},

    loginStatus({commit}) {
        accountApi.loginStatus().then(res => {
            let loginStatusRS = res.data
            if(loginStatusRS.success) {
                commit(types.UPDATE_LOGIN_STATUS_RS, {
                    loginStatusRS: loginStatusRS
                })
            }else {
                commit(types.UPDATE_LOGIN_STATUS_RS, {
                    loginStatusRS: loginStatusRS
                })
            }
            
        }).catch(() => {
            let loginStatusRS = {success: false}
            commit(types.UPDATE_LOGIN_STATUS_RS, {
                loginStatusRS: loginStatusRS
            })
        })
    },

    logOutAction({commit}, payload){
        accountApi.logOut(payload).then(res =>{
            let logoutRS = res.data;
            commit(types.UPDATE_LOGOUTRS, {
                logoutRS: logoutRS,
            })
        }).catch(() =>{
            let logoutRS = {success: false}
            commit(types.UPDATE_LOGOUTRS, {
                logoutRS: logoutRS,
            })
        });
    },

    logOutB2BAction({commit}, payload){
        accountApi.logOutB2B(payload).then(res =>{
            let logOutRS = "";
            logOutRS = res.data;
            commit(types.UPDATE_LOGOUTRS, {
                logOutRS: logOutRS,
            });
        });
    },

    changePasswordAction({commit}, payload){
        accountApi.changePasswordApi(payload).then(res =>{
            let changePWDRS = "";
            changePWDRS = res.data;
            commit(types.UPDATE_CHANGEPWDRS, {
                changePWDRS: changePWDRS,
            });
        });
    },
    async reloadLoginInfoAction({commit}){
        return await accountApi.getOwnInfo().then(res =>{
            let loginRS = res.data;
            if(loginRS.id !== undefined && loginRS.id != ''){
                loginRS.success = true
            }
            if(!loginRS.success){
                let title = 'Sorry!'
                let errorMsg = ''
                try{
                    errorMsg = loginRS.errors.errors[0].stringValue
                }catch{
                    errorMsg = "Username or password error"
                }
                loginRS.errorTitle = title
                loginRS.errorMsg = errorMsg
            }
            commit(types.UPDATE_LOGINRS, {
                loginRS: loginRS,
            })
        }).catch(error => {
            let errorRS = {}
            let title = 'Sorry!'
            let errorMsg = ''
            if(error.response){
                try{
                    errorMsg = error.response.data.errors.errors[0].stringValue
                }catch{
                    console.info(error.response)
                    errorMsg = "Some reponse errors"
                }

            }else if(error.request){
                console.info(error.request)
                errorMsg = "Some request errors"
            }else{
                console.info(error)
                errorMsg = "Some data errors"
            }
            errorRS.errorTitle = title
            errorRS.errorMsg = errorMsg
            errorRS.success = false
            commit(types.UPDATE_LOGINRS, {
                loginRS: errorRS
            })
        })
    },

    profileModify({commit}, payload){
        accountApi.profileModifyApi(payload).then(res =>{
            let profileModifyRS = "";
            profileModifyRS = res.data;
            commit(types.UPDATE_PROFILEMODIFYRS, {
                profileModifyRS: profileModifyRS,
            });
        });
    },

    //account list #TODO
    getAccounts ({commit}) {
        accountApi.getAccounts().then(res =>{
            let accountsRS = {}
            if(res.data.success !== undefined && !res.data.success){
                let title = 'Sorry!'
                let errorMsg = ''
                try{
                    errorMsg = res.data.errors.errors[0].stringValue
                }catch{
                    errorMsg = "Please log in first."
                }
                accountsRS.errorTitle = title
                accountsRS.errorMsg = errorMsg    
            }else{
                accountsRS = res.data
                accountsRS.success = true
            }
            commit(types.UPDATE_ACCOUNTS_RS, {
                accountsRS: accountsRS,
            })
        }).catch(error => {
            let errorRS = {}
            let title = 'Sorry!'
            let errorMsg = ''
            if(error.response){
                try{
                    errorMsg = error.response.data.errors.errors[0].stringValue
                }catch{
                    console.info(error.response)
                    errorMsg = "Some reponse errors"
                }
                
            }else if(error.request){
                console.info(error.request)
                errorMsg = "Some request errors"
            }else{
                console.info(error)
                errorMsg = "Some data errors"
            }
            errorRS.errorTitle = title
            errorRS.errorMsg = errorMsg
            errorRS.success = false
            commit(types.UPDATE_ACCOUNTS_RS, {
                accountsRS: errorRS,
            })
        })
    },

    getAllRoles ({commit}, payload) {
        accountApi.getAllRoles(payload.userLoginId).then(res => {
            let allRoles = []
            if(res.data.error) {
                allRoles = []
            }
            else {
                allRoles = res.data
            }
            commit(types.UPDATE_ALL_ROLES, {
                allRoles: allRoles
            })
        }).catch(error => {
            let errorRS = {}
            let title = 'Sorry!'
            let errorMsg = ''
            if(error.response){
                try{
                    errorMsg = error.response.data.errors.errors[0].stringValue
                }catch{
                    console.info(error.response)
                    errorMsg = "Some reponse errors"
                }
                
            }else if(error.request){
                console.info(error.request)
                errorMsg = "Some request errors"
            }else{
                console.info(error)
                errorMsg = "Some data errors"
            }
            errorRS.errorTitle = title
            errorRS.errorMsg = errorMsg
            errorRS.success = false
            commit(types.UPDATE_ALL_ROLES, {
                allRoles: []
            })
        })
    },

    getPermissionsById({commit}, payload) {
        accountApi.getPermissionsById(payload.userLoginId).then(res => {
            let permissions = res.data
            sessionStorage.setItem('permissions',JSON.stringify(permissions))
            commit(types.UPDATE_PERMISSIONS, {
                permissions: permissions
            })
        }).catch(error => {
            let errorRS = {}
            let title = 'Sorry!'
            let errorMsg = ''
            if(error.response){
                try{
                    errorMsg = error.response.data.errors.errors[0].stringValue
                }catch{
                    console.info(error.response)
                    errorMsg = "Some reponse errors"
                }
                
            }else if(error.request){
                console.info(error.request)
                errorMsg = "Some request errors"
            }else{
                console.info(error)
                errorMsg = "Some data errors"
            }
            errorRS.errorTitle = title
            errorRS.errorMsg = errorMsg
            errorRS.success = false
            commit(types.UPDATE_PERMISSIONS, {
                permissions: []
            })
        })
    }
}

export default actions;
