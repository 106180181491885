/**
 *  根据mutationTypes中常量定义的方法名来构造指定命名的函数，函数实现state中数据的状态转变
 * */
import * as mutationName from "./mutationTypes";
import RailUtils from "../../../utils/RailUtils";

const mutations = {
  [mutationName.UPDATE_SEARCH_CRITERIA](state, payload) {
    if (payload.searchInputValues) {
      // state.searchInputValues = payload.searchInputValues
      state.isRoundTrip = payload.isRoundTrip;
    } else {
      sessionStorage.setItem(
        "passCriteria",
        JSON.stringify(payload.passCriteria)
      );
      state.passCriteria = payload.passCriteria;
    }
  },

  // [mutationName.UPDATE_TRAIN_TICKETS_RS] (state, payload) {
  //    if (payload.outboundTrainTicketsRS) {
  //       state.outboundTrainTicketsRS = payload.outboundTrainTicketsRS
  //       if(!payload.outboundTrainTicketsRS.errors) {
  //          let outboundTrainTicketResults = RailUtils.getTrainTicketResults(payload.outboundTrainTicketsRS, payload.searchInputValues)
  //          state.outboundTrainTicketResults = outboundTrainTicketResults
  //       }
  //    }
  //    if(payload.inboundTrainTicketsRS){
  //       state.inboundTrainTicketsRS = payload.inboundTrainTicketsRS
  //       if(!payload.inboundTrainTicketsRS.errors) {
  //          let inboundTrainTicketResults = RailUtils.getTrainTicketResults(payload.inboundTrainTicketsRS, payload.searchInputValues)
  //          state.inboundTrainTicketResults = inboundTrainTicketResults
  //       }
  //    }
  //  },

  [mutationName.UPDATE_TRAIN_TICKETS_RS](state, payload) {
    state.trainTicketsRS = payload.trainTicketsRS;
    if (payload.trainTicketsRS.success) {
      let trainTicketResults = [];
      trainTicketResults = RailUtils.getTrainTicketResults(
        payload.trainTicketsRS,
        payload.searchInputValues
      );

      //车票信息
      if (trainTicketResults.inboundTrainTicketResults == undefined) {
        sessionStorage.removeItem("inboundTrainTicketResults");
      } else {
        sessionStorage.setItem(
          "inboundTrainTicketResults",
          JSON.stringify(trainTicketResults.inboundTrainTicketResults)
        );
      }

      if (trainTicketResults.outboundTrainTicketResults == undefined) {
        sessionStorage.removeItem("outboundTrainTicketResults");
      } else {
        sessionStorage.setItem(
          "outboundTrainTicketResults",
          JSON.stringify(trainTicketResults.outboundTrainTicketResults)
        );
      }

      state.searchInputValues = payload.searchInputValues;
      state.outboundTrainTicketResults =
        trainTicketResults.outboundTrainTicketResults;
      if (trainTicketResults.inboundTrainTicketResults) {
        state.inboundTrainTicketResults =
          trainTicketResults.inboundTrainTicketResults;
      }
    } else {
      state.searchInputValues = null;
      state.outboundTrainTicketResults = null;
      state.inboundTrainTicketResults = null;
      sessionStorage.removeItem("inboundTrainTicketResults");
      sessionStorage.removeItem("outboundTrainTicketResults");
    }
  },

  //更新来/回车程状态
  [mutationName.UPDATE_TRAIN_TICKETS](state, payload) {
    state.outboundTrainTicketResults = payload.outboundTrainTicketResults;
    if (payload.inboundTrainTicketResults) {
      state.inboundTrainTicketResults = payload.inboundTrainTicketResults;
    }
    state.searchInputValues = payload.searchInputValues;
    state.isRoundTrip = payload.isRoundTrip;
  },

  [mutationName.UPDATE_RAIL_PASSES_RS](state, payload) {
    state.railPassesRS = payload.railPassesRS;
    if (payload.railPassesRS.success) {
      let railPassResults = [];
      railPassResults = RailUtils.getRailPassResults(
        payload.railPassesRS,
        payload.passCriteria
      );
      sessionStorage.setItem(
        "railPassResults",
        JSON.stringify(railPassResults)
      );
      state.railPassResults = railPassResults;
    }
  },
  [mutationName.UPDATE_RAIL_PASSES](state, payload) {
    state.selectedPass = payload.selectedPass;
    state.passCriteria = payload.passCriteria;
  },

  [mutationName.UPDATE_TRAIN_TICKET_RESULTS](state, payload) {
    state.outboundTrainTicketResults = payload.outboundTrainTicketResults;
  },

  [mutationName.UPDATE_SELECTED_TICKET](state, payload) {
    if (payload.selectedOutboundTicket) {
      sessionStorage.setItem(
        "selectedOutboundTicket",
        JSON.stringify(payload.selectedOutboundTicket)
      );
      state.selectedOutboundTicket = payload.selectedOutboundTicket;
    }
    if (payload.selectedInboundTicket) {
      sessionStorage.setItem(
        "selectedInboundTicket",
        JSON.stringify(payload.selectedInboundTicket)
      );
      state.selectedInboundTicket = payload.selectedInboundTicket;
    }
  },

  [mutationName.UPDATE_TICKET_TICKET](state, payload) {
    if (payload.selectedOutboundTicket) {
      state.selectedOutboundTicket = payload.selectedOutboundTicket;
      state.isRoundTrip = payload.isRoundTrip;
    }
    if (payload.selectedInboundTicket) {
      state.selectedInboundTicket = payload.selectedInboundTicket;
      state.isRoundTrip = payload.isRoundTrip;
    }
  },

  [mutationName.UPDATE_SELECTED_PASS](state, payload) {
    sessionStorage.setItem(
      "selectedPass",
      JSON.stringify(payload.selectedPass)
    );
    state.selectedPass = payload.selectedPass;
  },

  [mutationName.UPDATE_SELECTED_VALIDITY](state, payload) {
    sessionStorage.setItem(
      "selectedValidity",
      JSON.stringify(payload.selectedValidity)
    );
    state.selectedValidity = payload.selectedValidity;
  },

  [mutationName.UPDATE_VALIDITY](state, payload) {
    state.selectedValidity = payload.selectedValidity;
  },

  [mutationName.UPDATE_CART](state, payload) {
    sessionStorage.setItem(
      "bookFromCart",
      JSON.stringify(payload.bookFromCart)
    );
    state.bookFromCart = payload.bookFromCart;
  },

  [mutationName.UPDATE_BOOKE_FROM_CART](state, payload) {
    state.bookFromCart = payload.bookFromCart;
  },

  [mutationName.UPDATE_BOOK_TICKET_RS](state, payload) {
    if (payload.bookInitiateRS) {
      state.bookInitiateRS = payload.bookInitiateRS;
    } else {
      state.bookTicketRS = payload.bookTicketRS;
    }
  }
};

export default mutations;
