import Vue from "vue";
import App from "./App.vue";
import {
  BootstrapVue,
  BIcon,
  BIconCircle,
  BIconCircleFill
} from "bootstrap-vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import VueMask from "v-mask";
import * as VueGoogleMaps from "vue2-google-maps";
import VueYoutube from "vue-youtube";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import "element-ui/lib/theme-chalk/index.css";

import vco from "v-click-outside";

import router from "./router";
import store from "@/state/store";
import i18n from "./i18n";
import permission from "./directive/permission";

import "@/assets/scss/app.scss";
import "@/assets/css/custom.css";

Vue.use(permission);

Vue.config.productionTip = false;
Vue.use(VueYoutube);
Vue.use(BootstrapVue);
Vue.use(vco);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueMask);
Vue.use(ElementUI, { locale });
Vue.use(require("vue-chartist"));
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places"
  },
  installComponents: true
});
Vue.component("apexchart", VueApexCharts);
Vue.component("BIcon", BIcon);
Vue.component("BIconCircle", BIconCircle);
Vue.component("BIconCircleFill", BIconCircleFill);
Vue.component("VuePhoneNumberInput", VuePhoneNumberInput);

Vue.prototype.resetSetItem = function(key, newVal) {
  if (key === "searchInputValues") {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
      setItem: function(k, val) {
        sessionStorage.setItem(k, val);

        // 初始化创建的事件
        newStorageEvent.initStorageEvent(
          "setItem",
          false,
          false,
          k,
          null,
          val,
          null,
          null
        );

        // 派发对象
        window.dispatchEvent(newStorageEvent);
      }
    };
    return storage.setItem(key, newVal);
  }
};

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
