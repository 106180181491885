import * as types from "./mutationsTypes"
import carrentalApi from "../../../api/carrentalApi";

const actions = {
    searchVehicles({commit}, payload){
        carrentalApi.searchVehicles(payload.vehicleRQ).then(res =>{
            let VehiclesSearchRS = "";
            VehiclesSearchRS = res.data;
            // console.log(res.data)
            commit(types.UPDATE_VEHICLES, {
                VehiclesSearchRS: VehiclesSearchRS,
            });
        });
    },

    searchVehicleRate({commit}, payload){
        carrentalApi.searchVehicleRate(payload.vehicleRateRQ).then(res =>{
            console.log(res)
            let VehicleRateRS = "";
            VehicleRateRS = res.data;
            // console.log(res.data)
            commit(types.UPDATE_VEHICLERATE, {
                VehicleRateRS: VehicleRateRS,
            });
        });
    }

}

export default actions;