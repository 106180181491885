/**
 * 整合给模块分离出来的逻辑模块，并向外暴露
 * */
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
    //使用命名空间避免命名冲突
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};