const state = {
    logedIn: false,
    accountInfo:{},
    loginStatusRS:{success: false},
    logoutRS:{},
    changePwdRS:{},
    profileModifyRS:{},
    //#TODO
    accountsRS: {},
    allRoles: [],
    permissions: []
}

export default state;

