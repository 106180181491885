/**
*  获取state中各类信息，包括原始信息与重构后的信息
* */

const getters = {
    oneWayFlightList(state){
        return state.oneWayFlightList
    },
    verifyPriceRS(state){
        return state.verifyPriceRS
    },
    bookRS(state){
        return state.bookRS
    },
    roundTripFlightList(state){
        return state.roundTripFlightList
    },
    mutipleFilghts(state){
        return state.mutipleCities
    },
}

export default getters;