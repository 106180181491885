/**
*  获取state中各类信息，包括原始信息与重构后的信息
* */


const getters = {
    accountInfo(state){
        return state.accountInfo;
    },
    logedIn (state) {
        return state.logedIn
    },
    loginStatusRS (state) {
        return state.loginStatusRS
    },
    logoutRS (state){
        return state.logoutRS
    },
    profileModifyRS(state){
        return state.profileModifyRS
    },
    accountsRS (state) {
        return state.accountsRS
    },
    allRoles (state) {
        return state.allRoles
    },
    permissions(state) {
        return state.permissions
    }
}

export default getters;