/**
*  获取state中各类信息，包括原始信息与重构后的信息
* */

const getters = {
    bookingHistoryRS(state){
        return state.bookingHistoryRS;
    },
    booking(state){
        return state.booking;
    },
    selectedBooking(state) {
        return state.selectedBooking
    },
    bookingDetailsRS(state) {
        return state.bookingDetailsRS
    },
    bookingDetails(state) {
        return state.bookingDetails
    },
    
    userInfo(state){
        return state.userInfo;
    },
    cancelRS(state){
        return state.cancelRS;
    },
    shoppingCart(state){
        return state.shoppingCart;
    },
    cartNumber(state){
        return state.cartNumber;
    }
}

export default getters;