/*
* 代替视图，与mutation进行交互，根据用户需求通过hotelApi工具包请求后端数据，通过mutations更新state中的状态
* */
import * as types from "./mutationsTypes"
import activityApi from "../../../api/activityApi";

const actions = {
    searchActivities({commit}, payload){
        activityApi.searchActivities(payload.searchRQ).then(res =>{
            let activitiesSearchRS = "";
            activitiesSearchRS = res.data;
            // console.log(res.data)
            commit(types.UPDATE_SEARCHRS, {
                activitiesSearchRS: activitiesSearchRS,
            });
        });
    },

    availActivity({commit}, payload){
        activityApi.availActivity(payload.activityAvailRQ).then(res =>{
            // console.log(res)
            let activityAvailRS = "";
            activityAvailRS = res.data;
            commit(types.UPDATE_AVAILRS, {
                activityAvailRS: activityAvailRS,
            });
        });
    }

}

export default actions;