/**
 *  根据mutationTypes中常量定义的方法名来构造指定命名的函数，函数实现state中数据的状态转变
 * */
import * as types from "./mutationsTypes"
const mutations = {
    [types.UPDATE_LOGINRS](state, payload){
        state.logedIn = true
        state.accountInfo = payload.loginRS
        state.permissions = payload.loginRS.permissions
        sessionStorage.setItem('permissions', JSON.stringify(state.permissions))
    },

    [types.UPDATE_LOGIN_STATUS_RS](state, payload) {
        state.loginStatusRS = payload.loginStatusRS
    },

    [types.UPDATE_LOGOUTRS](state, payload){
        state.logedIn = false
        state.logoutRS = payload.logoutRS
    },

    [types.UPDATE_CHANGEPWDRS](state, payload){
        state.changePwdRS = payload
        // state.accountInfo = payload
    },
    [types.UPDATE_PROFILEMODIFYRS](state, payload){
        state.profileModifyRS = payload
        // state.accountInfo = payload
    },
    //account list #TODO
    [types.UPDATE_ACCOUNTS_RS](state, payload){
        state.accountsRS = payload.accountsRS
        // state.accountInfo = payload
    },
    [types.UPDATE_ALL_ROLES](state, payload) {
        state.allRoles = payload.allRoles
    },
    [types.UPDATE_PERMISSIONS](state, payload) {
        state.permissions = payload.permissions
    }
}

export default mutations;