import Vue from 'vue'
import Vuex from 'vuex'

// modules 文件夹下的index.js会加载全部的*.js模块，不需要单独配置模块文件夹
import modules from './modules'

// #TODO
import account from './modules/account'
import activity from './modules/activity'
import air from './modules/air'
import booking from './modules/booking'
import carrental from './modules/carrental'
import dynamicpkg from './modules/dynamicpkg'
import hotel from './modules/hotel'
import rail from "./modules/rail"

modules.account = account
modules.activity = activity
modules.air = air
modules.booking = booking
modules.carrental = carrental
modules.dynamicpkg = dynamicpkg
modules.hotel = hotel
modules.rail = rail

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})

export default store

