/**
 *  根据mutationTypes中常量定义的方法名来构造指定命名的函数，函数实现state中数据的状态转变
 * */
import * as types from "./mutationsTypes"
const mutations = {
    [types.UPDATE_SEARCHRS](state, payload){
        console.log(payload.activitiesSearchRS)
        state.activitySearchRS = payload.activitiesSearchRS;
    },

    [types.UPDATE_AVAILRS](state, payload){
        console.log(payload)
        console.log(payload.activityAvailRS)
        state.activityDetail = payload.activityAvailRS;
        state.necessaryInfoDetailList=[];
        for(let i = 0; i <payload.activityAvailRS.tourActivityInfoList.length; i++){
            let item = payload.activityAvailRS.tourActivityInfoList[i];
            let necessaryInfo = {
                tourName:'',
                tourId:'',
                groupName:'',
                groupRph:'',
                groupPrice:{
                    currencyCode:'',
                    amount:''
                },
                pricePolicy:[],
                location:{
                    start:{
                        countryName:'',
                        cityName:'',
                        locationName:''
                    },
                    end:{
                        countryName:'',
                        cityName:'',
                        locationName:''
                    }

                },
                cancelPolicy:'',
                fareList:[]
            }
            necessaryInfo.tourName = item.basicInfo.shortName;
            necessaryInfo.tourId = item.basicInfo.tourActivityID;
            necessaryInfo.groupName = item.pricing.groups[0].groupName;
            necessaryInfo.groupRph = item.pricing.groups[0].groupCode;
            necessaryInfo.groupPrice.currencyCode = item.pricing.summary.currencyCode;
            necessaryInfo.groupPrice.amount = item.pricing.groups[0].prices[0].amount;
            for(let j = 0;j<item.locations.length;j++){
                if(item.locations[j].locationTypeId=="startingPoint"){
                    necessaryInfo.location.start.locationName = item.locations[j].locationName;
                    necessaryInfo.location.start.countryName = item.locations[j].address.countryName.text;
                    necessaryInfo.location.start.cityName = item.locations[j].region.regionName;
                }
                else{
                    if(item.locations[j].address.countryName && item.locations[j].region){
                        necessaryInfo.location.end.countryName = item.locations[j].address.countryName.text;
                        necessaryInfo.location.end.cityName = item.locations[j].region.regionName;
                        necessaryInfo.location.end.locationName = item.locations[j].locationName;
                    }
                }
            }
            necessaryInfo.pricePolicy =item.pricing.participantCategories;
            necessaryInfo.cancelPolicy =fmtDate(item.policies.cancel.cancelDeadline);
            //todo fareList
            let FareList=[];
            for(let k=0;k<item.pricing.groups[0].prices.length;k++){//对价格种类进行forloop
                console.log(1);
                console.log(item)
                if(item.pricing.groups[0].prices[k].operationDates){
                    for(let j=0;j<item.pricing.groups[0].prices[k].operationDates.length;j++){
                        let FareDate={
                            fare:'',
                            StartDate:'',
                            EndDate:''
                        };
                        FareDate.fare=item.pricing.groups[0].prices[k].amount;
                        FareDate.StartDate = getDateToDAte(item.pricing.groups[0].prices[k].operationDates[j].start)
                        FareDate.EndDate = getDateToDAte(item.pricing.groups[0].prices[k].operationDates[j].end)
                        FareList.push(FareDate)
                    }
                }
            }
            console.log(FareList)
            necessaryInfo.fareList = FareList
            state.necessaryInfoDetailList.push(necessaryInfo)
        }
    }

}
function fmtDate(obj){
    var date =  new Date(obj);
    var y = 1900+date.getYear();
    var m = "0"+(date.getMonth()+1);
    var d = "0"+date.getDate();
    return y+"-"+m.substring(m.length-2,m.length)+"-"+d.substring(d.length-2,d.length);
}
function getDateToDAte(date) {
    var dateNow = new Date(date);
    var YY = dateNow.getFullYear() + '-';
    var MM = (dateNow.getMonth() + 1 < 10 ? '0' + (dateNow.getMonth() + 1) : dateNow.getMonth() + 1) + '-';
    var DD = (dateNow.getDate() < 10 ? '0' + (dateNow.getDate()) : dateNow.getDate());
    return YY + MM + DD +"";
}

export default mutations;