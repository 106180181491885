/**
*  获取state中各类信息，包括原始信息与重构后的信息
* */

const getters = {
    searchInputValues (state) {
        return state.searchInputValues
    },
    isRoundTrip (state) {
        return state.isRoundTrip
    },
    passCriteria (state) {
        return state.passCriteria
    },
    trainTicketsRS (state) {
        return state.trainTicketsRS       
    },
    // outboundTrainTicketsRS (state) {
    //     return state.outboundTrainTicketsRS       
    // },
    // inboundTrainTicketsRS (state) {
    //     return state.inboundTrainTicketsRS       
    // },
    railPassesRS (state) {
        return state.railPassesRS
    },
    outboundTrainTicketResults (state) {
        return state.outboundTrainTicketResults
    },
    inboundTrainTicketResults (state) {
        return state.inboundTrainTicketResults
    },
    railPassResults (state) {
        return state.railPassResults
    },
    selectedOutboundTicket (state) {
        return state.selectedOutboundTicket
    },
    selectedInboundTicket (state) {
        return state.selectedInboundTicket
    },
    selectedPass (state) {
        return state.selectedPass
    },
    selectedValidity (state) {
        return state.selectedValidity
    },
    cartNumber (state) {
        return state.cartNumber
    },
    bookFromCart (state) {
        return state.bookFromCart
    },
    bookInitiateRS (state) {
        return state.bookInitiateRS
    },
    bookTicketRS (state) {
        return state.bookTicketRS
    },
    ticketBooked (state) {
        return state.ticketBooked
    }
}

export default getters