/**
 *  根据mutationTypes中常量定义的方法名来构造指定命名的函数，函数实现state中数据的状态转变
 * */
import * as types from "./mutationsTypes"
const mutations = {
    [types.UPDATE_PACKAGEAVAILRS](state, payload){
        console.log(payload);
        state.packageCityAvailRS = payload.packageAvailRS
        state.dynamicpkgRS = payload.packageAvailRS
    },

    [types.UPDATE_PACKAGEAVAILHOTELRS](state, payload){
        console.log(payload);
        state.selectedPkgHotel = {
            ttiCode:'',
            rooms:[],
            room:{
                roomType:"",
                roomTypeCode:"",
                Price:{
                    currencyCode:"",
                    amountIncludingMarkup:"",
                    amountBeforeTax:"",
                    amountAfterTax:"",
                },
                maxOccupancy:"",
                minOccupancy:"",
                meals:"",
                freeCancelTime:"",
                referenceId:"",
                hotelCodeContext:"",
                fitWords:"",
                star:0,
                alternate:null,
                quantity:0,
                datePrice:[]
            },
            description: {
                name: "",
                location:{
                    cityName: "",
                    countryCode: "",
                    countryName: "",
                    address: "",
                    position: {
                        longtitude: "",
                        latitude: ""
                    }
                },
                photos: [],
                overView:""
            },
            amenities:[],
            amenity:{
                serviceItem:"",
                code:""
            },
            rating:0,
            roomImgList:[],
            star:0,
            mainImgUrl:'',
            lowestPrice:0
        }
        state.selectedPkgHotel.description.name = payload.packageAvailHotelRS.searchResults.hotelResults[0].roomStays.roomStays[0].basicPropertyInfo.hotelName
        state.selectedPkgHotel.description.location.position.latitude = payload.packageAvailHotelRS.searchResults.hotelResults[0].roomStays.roomStays[0].basicPropertyInfo.position.latitude
        state.selectedPkgHotel.description.location.position.longtitude = payload.packageAvailHotelRS.searchResults.hotelResults[0].roomStays.roomStays[0].basicPropertyInfo.position.longitude
        state.selectedPkgHotel.description.overView = payload.packageAvailHotelRS.searchResults.hotelResults[0].roomStays.roomStays[0].basicPropertyInfo.shortDescriptions
        state.selectedPkgHotel.ttiCode = payload.packageAvailHotelRS.searchResults.hotelResults[0].roomStays.roomStays[0].basicPropertyInfo.TTIcode
        state.selectedPkgHotel.description.photos.push(payload.packageAvailHotelRS.searchResults.hotelResults[0].roomStays.roomStays[0].basicPropertyInfo.mainImageURL)
        state.selectedPkgHotel.rooms=[];
        let lowestPrice = 0
        for(let i=0;i<payload.packageAvailHotelRS.searchResults.hotelResults[0].roomStays.roomStays.length;i++){
            state.selectedPkgHotel.room ={

                roomType:"",
                roomTypeCode:"",
                Price:{
                    currencyCode:"",
                    amountIncludingMarkup:"",
                    amountBeforeTax:"",
                    amountAfterTax:"",
                },
                maxOccupancy:"",
                minOccupancy:"",
                meals:"",
                propertyMeals:'',
                freeCancelTime:"",
                propertyFreeCancelTime:'',
                referenceId:"",
                hotelCodeContext:"",
                fitWords:"",
                propertyFitWords:'',
                star:0,
                alternate:null,
                quantity:0,
                datePrice:[],
                roomPolicyList:[]
            };
            let datePriceInMap = []
            let item = payload.packageAvailHotelRS.searchResults.hotelResults[0].roomStays.roomStays[i];
            for(let j=0;j<item.roomRates.roomRates[0].rates.rateList.length;j++){
                datePriceInMap[item.roomRates.roomRates[0].rates.rateList[j].effectiveDate] = item.roomRates.roomRates[0].rates.rateList[j].total.amountIncludingMarkup
            }
            let datePriceArray = []
            for(var date in datePriceInMap){
                let datePriceObject = {
                    price:'',
                    date:''
                };
                datePriceObject.price = datePriceInMap[date]
                datePriceObject.date = date
                datePriceArray.push(datePriceObject)
            }
            if(lowestPrice> item.total.amountIncludingMarkup || lowestPrice==0){
                lowestPrice =  item.total.amountIncludingMarkup
            }
            state.selectedPkgHotel.lowestPrice = lowestPrice
            state.selectedPkgHotel.room.datePrice = datePriceArray
            state.selectedPkgHotel.room.roomType = item.roomTypes[0].roomType;
            state.selectedPkgHotel.mainImgUrl = item.basicPropertyInfo.mainImageURL
            state.selectedPkgHotel.room.roomTypeCode = item.roomTypes[0].roomTypeCode;
            state.selectedPkgHotel.room.Price.currencyCode = item.total.currencyCode;
            state.selectedPkgHotel.room.Price.amountIncludingMarkup = item.total.amountIncludingMarkup;
            state.selectedPkgHotel.room.Price.amountBeforeTax = item.total.amountBeforeTax;
            state.selectedPkgHotel.room.Price.amountAfterTax = item.total.amountAfterTax;
            state.selectedPkgHotel.room.referenceId = item.reference.id;
            state.selectedPkgHotel.room.hotelCodeContext = item.basicPropertyInfo.hotelCodeContext;
            state.selectedPkgHotel.room.maxOccupancy = item.roomTypes[0].occupancies[0].maxOccupancy;
            state.selectedPkgHotel.room.minOccupancy = item.roomTypes[0].occupancies[0].minOccupancy;
            state.selectedPkgHotel.room.amenities ="Nothing";
            state.selectedPkgHotel.room.roomTypeCode = item.roomTypes[0].roomTypeCode;
            state.selectedPkgHotel.room.quantity = item.roomTypes[0].quantity
            state.selectedPkgHotel.room.roomPolicyList.push(item.ratePlans[0].additionalDetails)
            state.selectedPkgHotel.room.ratePlanCode=item.ratePlans[0].ratePlanCode

            if(item.roomTypes[0].alternate==true){
                state.selectedPkgHotel.room.alternate = item.roomTypes[0].alternate;
            }
            else{
                state.selectedPkgHotel.room.alternate = item.roomTypes[0].alternate;
            }
            //todo userrating
            state.selectedPkgHotel.star = item.basicPropertyInfo.rating;
            state.selectedPkgHotel.rating = item.basicPropertyInfo.awards[0].rating;
            let fitwordMin = ""
            if(state.selectedPkgHotel.room.minOccupancy>1){
                fitwordMin = "1 room fit ocupancy "+state.selectedPkgHotel.room.minOccupancy+" adults to "
                // +state.selectedPkgHotel.room.maxOccupancy+"adults"
            }else{
                fitwordMin = "1 room fit ocupancy "+state.selectedPkgHotel.room.minOccupancy+" adult to "
            }
            let fitwordMax = ""
            if(state.selectedPkgHotel.room.maxOccupancy>1){
                fitwordMax = state.selectedPkgHotel.room.maxOccupancy+" adults"
            }else{
                fitwordMax = state.selectedPkgHotel.room.maxOccupancy+" adult"
            }

            state.selectedPkgHotel.room.fitWords = fitwordMin+fitwordMax;
            let propertyFitwordMin = ""
            if(state.selectedPkgHotel.room.minOccupancy>1){
                propertyFitwordMin = "一間房間適合"+state.selectedPkgHotel.room.minOccupancy+"個成人到"
                // +state.selectedPkgHotel.room.maxOccupancy+"adults"
            }else{
                propertyFitwordMin = "一間房間適合"+state.selectedPkgHotel.room.minOccupancy+"個成人到"
            }
            let propertyFitwordMax = ""
            if(state.selectedPkgHotel.room.maxOccupancy>1){
                propertyFitwordMax = state.selectedPkgHotel.room.maxOccupancy+"個成人"
            }else{
                propertyFitwordMax = state.selectedPkgHotel.room.maxOccupancy+"個成人"
            }

            state.selectedPkgHotel.room.propertyFitWords = propertyFitwordMin+propertyFitwordMax;

            // if (item.ratePlans[0].mealsIncluded.breakfast && item.ratePlans[0].mealsIncluded.Lunch && item.ratePlans[0].mealsIncluded.dinner){
            //     state.selectedPkgHotel.room.meals = 'Breakfast, Lunch, Dinner';
            //     state.selectedPkgHotel.room.propertyMeals = '早餐，午餐，晚餐';
            // }
            // else if (item.ratePlans[0].mealsIncluded.breakfast && item.ratePlans[0].mealsIncluded.Lunch && !item.ratePlans[0].mealsIncluded.dinner){
            //     state.selectedPkgHotel.room.meals = 'Breakfast, Lunch';
            //     state.selectedPkgHotel.room.propertyMeals = '早餐，午餐';
            // }
            // else if(item.ratePlans[0].mealsIncluded.breakfast && !item.ratePlans[0].mealsIncluded.Lunch && !item.ratePlans[0].mealsIncluded.dinner){
            //     state.selectedPkgHotel.room.meals = 'Breakfast';
            //     state.selectedPkgHotel.room.propertyMeals = '早餐';
            // }
            // else {
            //     state.selectedPkgHotel.room.meals = 'None';
            //     state.selectedPkgHotel.room.propertyMeals = '無';
            // }

            if (item.ratePlans[0].mealsIncluded.breakfast && item.ratePlans[0].mealsIncluded.Lunch && item.ratePlans[0].mealsIncluded.Dinner){
                state.selectedPkgHotel.room.meals = 'Breakfast, Lunch, Dinner';
                state.selectedPkgHotel.room.propertyMeals = '早餐，午餐，晚餐';
            }//早午晚
            else if (item.ratePlans[0].mealsIncluded.breakfast && item.ratePlans[0].mealsIncluded.Lunch && !item.ratePlans[0].mealsIncluded.Dinner){
                state.selectedPkgHotel.room.meals = 'Breakfast, Lunch';
                state.selectedPkgHotel.room.propertyMeals = '早餐，午餐';
            }//早午
            else if(item.ratePlans[0].mealsIncluded.breakfast && !item.ratePlans[0].mealsIncluded.Lunch && !item.ratePlans[0].mealsIncluded.Dinner){
                state.selectedPkgHotel.room.meals = 'Breakfast';
                state.selectedPkgHotel.room.propertyMeals = '早餐';
            }//早
            else if(item.ratePlans[0].mealsIncluded.breakfast && !item.ratePlans[0].mealsIncluded.Lunch && item.ratePlans[0].mealsIncluded.Dinner){
                state.selectedPkgHotel.room.meals = 'Breakfast and Dinner';
                state.selectedPkgHotel.room.propertyMeals = '早餐,晚餐';
            }//早晚
            else if(!item.ratePlans[0].mealsIncluded.breakfast && item.ratePlans[0].mealsIncluded.Lunch && item.ratePlans[0].mealsIncluded.Dinner){
                state.selectedPkgHotel.room.meals = 'Lunch and Dinner';
                state.selectedPkgHotel.room.propertyMeals = '午餐,晚餐';
            }//午晚
            else if(!item.ratePlans[0].mealsIncluded.breakfast && !item.ratePlans[0].mealsIncluded.Lunch && item.ratePlans[0].mealsIncluded.Dinner){
                state.selectedPkgHotel.room.meals = 'Dinner';
                state.selectedPkgHotel.room.propertyMeals = '晚餐';
            }//晚
            else if(!item.ratePlans[0].mealsIncluded.breakfast && item.ratePlans[0].mealsIncluded.Lunch && !item.ratePlans[0].mealsIncluded.Dinner){
                state.selectedPkgHotel.room.meals = 'Lunch';
                state.selectedPkgHotel.room.propertyMeals = '午餐';
            }//午
            else {
                state.selectedPkgHotel.room.meals = 'None';
                state.selectedPkgHotel.room.propertyMeals = '無';
            }//无
            if(item.cancelPenalties!=null){
                if(item.cancelPenalties.cancelPenalties[0].deadline!=null){
                    state.selectedPkgHotel.room.freeCancelTime = item.cancelPenalties.cancelPenalties[0].deadline;
                    // let time = new Date(item.cancelPenalties.cancelPenalties[0].deadline)
                    // var YY = time.getFullYear() + '-';
                    // var MM = (time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1) + '-';
                    // var DD = (time.getDate() < 10 ? '0' + (time.getDate()) : time.getDate());
                    // state.selectedPkgHotel.room.freeCancelTime =YY + MM + DD +""
                }else{
                    state.selectedPkgHotel.room.freeCancelTime = "Non-Cancelable";
                    state.selectedPkgHotel.room.propertyFreeCancelTime = "不可取消";

                }
            }else{
                state.selectedPkgHotel.room.freeCancelTime = "Non-Cancelable";
                state.selectedPkgHotel.room.propertyFreeCancelTime = "不可取消";
            }
            state.selectedPkgHotel.rooms.push(state.selectedPkgHotel.room)
        }
    },


    [types.UPDATE_PACKAGEVERIFYRS](state, payload){
        console.log(payload);
        state.pkgVerifyRS = {}
        state.pkgVerifyRS = payload.packageVerifyRS.dynamicPackage
    },
    [types.UPDATE_PACKAGEPREBOOKRS](state, payload){
        console.log(payload);
        state.pkgPreBookRS = {}
        state.pkgPreBookRS = payload.packagePreBookRS.dynamicPackage
    },


}

export default mutations;