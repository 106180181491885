/*
* 代替视图，与mutation进行交互，根据用户需求通过hotelApi工具包请求后端数据，通过mutations更新state中的状态
* */
import * as types from "./mutationsTypes"
import packageApi from "../../../api/packageApi";

const actions = {
    searchPackageAvailCitysAction({commit}, payload){
        packageApi.searchPackageAvailCitysApi(payload.packageAvailRQ).then(res =>{
            let packageAvailRS = "";
            packageAvailRS = res.data;
            commit(types.UPDATE_PACKAGEAVAILRS, {
                packageAvailRS: packageAvailRS,
            });
        });
    },

    searchPackageAvailHotelAction({commit}, payload){
        packageApi.searchPackageAvailHotelApi(payload.packageHotelAvailRQ).then(res =>{
            let packageAvailHotelRS = "";
            packageAvailHotelRS = res.data;
            commit(types.UPDATE_PACKAGEAVAILHOTELRS, {
                packageAvailHotelRS: packageAvailHotelRS,
            });
        });
    },


    packageVerifyAction({commit}, payload){
        packageApi.packageVerifyApi(payload.packageVerifyRQ).then(res =>{
            let packageVerifyRS = "";
            packageVerifyRS = res.data;
            commit(types.UPDATE_PACKAGEVERIFYRS, {
                packageVerifyRS: packageVerifyRS,
            });
        });
    },

    packagePreBookAction({commit}, payload){
        packageApi.packagePreBookApi(payload.packagePreBookRQ).then(res =>{
            let packagePreBookRS = "";
            packagePreBookRS = res.data;
            commit(types.UPDATE_PACKAGEPREBOOKRS, {
                packagePreBookRS: packagePreBookRS,
            });
        });
    },

}

export default actions;