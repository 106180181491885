/**
*  获取state中各类信息，包括原始信息与重构后的信息
* */

const getters = {
    VehiclesRS(state){
        if (state.VehiclesRS == undefined){
            return {}
        }
        return state.VehiclesRS;
    },
    Vehicles(state){
        return state.Vehicles
    },
    VehicleInfoDetail(state){
        return state.VehicleInfoDetail;
    },
    vehicleDetailFees(state){
        let filterArr = state.VehicleInfoDetail.VehicleRateFeeAndTax.filter(fee => !fee.taxInclusive);
        filterArr.forEach(item => {
            if (item.currencyCode=="USD"){
                item.currencyCode = "$";
            }else if (item.currencyCode=="HKD"){
                item.currencyCode = "HK$";
            }else {
                item.currencyCode = "HK$";
            }
        })
        return filterArr;
    },
    vehicleDetailFeesTotal(state){
        let price = {
            total : 0,
            currencyCode:""
        }
        let filterArr = state.VehicleInfoDetail.VehicleRateFeeAndTax.filter(fee => !fee.taxInclusive);
        filterArr.forEach(item => {
            if (item.currencyCode=="USD"){
                item.currencyCode = "$";
            }else if (item.currencyCode=="HKD"){
                item.currencyCode = "HK$";
            }else {
                item.currencyCode = "HK$";
            }
            price.total +=item.amount
            price.currencyCode = item.currencyCode;
        })
        return price;
    },
    vehicleDetailTaxes(state){
        let filterArr = state.VehicleInfoDetail.VehicleRateFeeAndTax.filter(fee => fee.taxInclusive);
        return filterArr;
    },
    vehicleDetailTaxTotal(state){
        let price = {
            total : 0,
            currencyCode:""
        }
        let filterArr = state.VehicleInfoDetail.VehicleRateFeeAndTax.filter(fee => fee.taxInclusive);
        filterArr.forEach(item => {
            if (item.currencyCode=="USD"){
                item.currencyCode = "$";
            }else if (item.currencyCode=="HKD"){
                item.currencyCode = "HK$";
            }else {
                item.currencyCode = "HK$";
            }
            price.total +=item.amount
            price.currencyCode = item.currencyCode;
        })
        return price;
    },
    vehicleDetailExtras(state){
        let extras = [];
        state.VehicleInfoDetail.vehicleExtras.forEach(item=>{
            let extra={
                name:"",
                currencyCode:"",
                amount:""
            }
            if (item.charge.currencyCode=="USD"){
                item.charge.currencyCode = "$";
            }else if (item.charge.currencyCode=="HKD"){
                item.charge.currencyCode = "HK$";
            }else {
                item.charge.currencyCode = "HK$";
            }
            if (item.equipment.description!=undefined){
                extra.name=item.equipment.description+"("+item.equipment.code+"-"+item.equipment.equipType+")";
            }else {
                extra.name=item.equipment.code+"-"+item.equipment.equipType;
            }
            extra.currencyCode = item.charge.currencyCode;
            extra.amount = item.charge.amount
            extras.push(extra)
        })
        return extras
    },
    vehicleDetailInsurance(state){
        let insurances = [];
        state.VehicleInfoDetail.insurance.forEach(item=>{
            let insurance={
                name:"",
                currencyCode:"",
                amount:""
            }
            if (item.charge.currencyCode=="USD"){
                item.charge.currencyCode = "$";
            }else if (item.charge.currencyCode=="HKD"){
                item.charge.currencyCode = "HK$";
            }else {
                item.charge.currencyCode = "HK$";
            }
            insurance.name=item.charge.description
            insurance.currencyCode = item.charge.currencyCode;
            insurance.amount = item.charge.amount
            insurances.push(insurance)
        })
        return insurances
    }
}

export default getters;