const state = {
    bookingHistoryRS:[],
    booking: {},
    selectedBooking: {},
    bookingDetailsRS: {},
    bookingDetails: {},
    userInfo:{

    },
    cancelRS:{},
    shoppingCart: [],
    cartNumber: 0
}

export default state;

