const state = {
    availRS: {},
    hotels:[],
    hotel:{
        hotelName:"",
        Price:{
            currencyCode:"",
            maxPrice:"",
            minPrice:"",
            price:""
        },
        rating:"",
        shortDescription:"",
        ttiCode:"",
        star:""
    },
    selectedHotel: {
        rooms:[],
        room:{
            roomType:"",
            roomTypeCode:"",
            Price:{
                currencyCode:"",
                amountIncludingMarkup:"",
                amountBeforeTax:"",
                amountAfterTax:"",
            },
            maxOccupancy:"",
            minOccupancy:"",
            meals:"",
            freeCancelTime:"",
            referenceId:"",
            hotelCodeContext:"",
            fitWords:"",
            star:0,
            alternate:null,
            quantity:0,
            datePrice:[]
        },
        description: {
            name: "",
            location:{
                cityName: "",
                countryCode: "",
                countryName: "",
                address: "",
                position: {
                    longtitude: "",
                    latitude: ""
                }
            },
            photos: [],
            overView:""
        },
        amenities:[],
        amenity:{
            serviceItem:"",
            code:""
        },
        rating:0,
        roomImgList:[],
        star:0,
        mainImgUrl:'',
        lowestPrice:0
    },
    hotelDescription:{
        description: {
            name: "",
            location:{
                cityName: "",
                countryCode: "",
                countryName: "",
                address: "",
                position: {
                    longtitude: "",
                    latitude: ""
                }
            },
            photos: [],
            overView:""
        },
        amenities:[],
        amenity:{
            serviceItem:"",
            code:""
        },
        roomImgList:[]
    },
    selectedRoom: {
    },
    passengers: [{
        adultCount:"",
        childCount:"",
        adult:{
            firstName:"",
            lastName:"",
            phoneNumber:"",
            countryCode:"",
            emailAddress:"",
            dateOfBirth:"",

        }
    }],
    necessaryInfo: {},
    passengerConfirmation:[],
    hotelRoomConfirmation:{
        hotelName:"",
        roomType:"",
        checkin:"",
        checkout:"",
        currencyCode:"",
        totalPrice:"",
        roomPrice:"",
        roomTypeCode:'',
        hotelCodeContext:"",
        ttiCode:"",
        referenceId:""
    },
    confirmRS:{},
    B2BBookRS:{},
    preBookRS:{}
}

export default state;

