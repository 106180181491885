/**
 *  根据mutationTypes中常量定义的方法名来构造指定命名的函数，函数实现state中数据的状态转变
 * */
import * as types from "./mutationsTypes"
const mutations = {
    [types.UPDATE_LOWFAREFLIGHTSRS](state, payload){
        console.log(payload.lowFareFlightRS);
        console.log("进入单程");
        state.oneWayFlightList = [];
        for(let i=0;i<payload.lowFareFlightRS.pricedItineraryList.length;i++){
            let flightInfo={
                departureDateTime:"",
                departureAirPort:"",
                arrivalAirPort:"",
                departureTime:"",
                arrivalTime:"",
                departureDate:"",
                arrivalDate:"",
                flightTime:"",
                stop:"",
                currencyCode:"",
                baseFare:"",
                taxesFare:"",
                totalFare:"",
                cabinClass:"",
                airItineraryRPH:"",
                stopStations:[],
                adultFare:0,
                childFare:0,
                adultTaxes:0,
                childTaxes:0,
                airLineCode:"",
                flightNumber:"",
                arrivalflightNumber:"",
                meals:"",
                baggage:"",
                baggageUnit:"",
                bookingSourceType:"",
                image:"",
                airItinerary:{},
                priceRequestInformation:{},
                equipmentCode:''
            }
            let item = payload.lowFareFlightRS.pricedItineraryList[i];
            let lastSegmentIndex = item.airItinerary.originDestinationOptions[0].flightSegments.length-1
            flightInfo.stop = item.airItinerary.originDestinationOptions[0].flightSegments.length-1
            if(flightInfo.stop>0){
                for(let i=0;i<item.airItinerary.originDestinationOptions[0].flightSegments.length;i++){
                    let stopItem=item.airItinerary.originDestinationOptions[0].flightSegments[i]
                    let stopInfo = {
                        departureDateTime:"",
                        departureAirPort:"",
                        arrivalAirPort:"",
                        departureTime:"",
                        arrivalTime:"",
                        departureDate:"",
                        arrivalDate:"",
                        preRph:'',
                        flightTime:"",
                        totalFare:"",
                        cabinClass:"",
                        airItineraryRPH:"",
                        numberInParty:"",
                        flightNumber:"",
                        bookingSourceType:"",
                        image:"",
                        equipmentCode:''
                    }
                    stopInfo.preRph = item.airItinerary.airItineraryRPH
                    stopInfo.departureDateTime =stopItem.departureDateTime
                    stopInfo.arrivalDateTime =stopItem.arrivalDateTime
                    stopInfo.departureAirPort =stopItem.departureAirportCode
                    stopInfo.arrivalAirPort =stopItem.arrivalAirportCode
                    stopInfo.departureTime =fmtClock(stopItem.departureDateTime)
                    stopInfo.arrivalTime =fmtClock(stopItem.arrivalDateTime)
                    stopInfo.departureDate = fmtSpeDate(stopItem.departureDateTime)
                    stopInfo.arrivalDate = fmtSpeDate(stopItem.arrivalDateTime)
                    stopInfo.flightTime = timeBetween(stopItem.departureDateTime,stopItem.arrivalDateTime);
                    stopInfo.flightNumber =stopItem.flightNumber;
                    stopInfo.airLineCode = stopItem.marketingAirlineCode;
                    stopInfo.bookingSourceType = item.bookingSourceType
                    stopInfo.image ="http://images.skywidetech.com/airlines/"+stopItem.marketingAirlineCode+".gif";
                    stopInfo.cabinClass = stopItem.cabinClass;
                    stopInfo.airItineraryRPH = stopItem.rph
                    stopInfo.numberInParty = stopItem.numberInParty
                    stopInfo.equipmentCode = stopItem.equipmentCode
                    flightInfo.stopStations.push(stopInfo)
                }
            }
            flightInfo.equipmentCode = item.airItinerary.originDestinationOptions[0].flightSegments[0].equipmentCode
            flightInfo.departureAirPort = item.airItinerary.originDestinationOptions[0].flightSegments[0].departureAirportCode
            flightInfo.arrivalAirPort = item.airItinerary.originDestinationOptions[0].flightSegments[lastSegmentIndex].arrivalAirportCode
            flightInfo.departureTime = fmtClock(item.airItinerary.originDestinationOptions[0].flightSegments[0].departureDateTime)
            flightInfo.arrivalTime = fmtClock(item.airItinerary.originDestinationOptions[0].flightSegments[lastSegmentIndex].arrivalDateTime)
            flightInfo.departureDate = fmtSpeDate(item.airItinerary.originDestinationOptions[0].flightSegments[0].departureDateTime)
            flightInfo.departureDateTime = item.airItinerary.originDestinationOptions[0].flightSegments[0].departureDateTime
            flightInfo.arrivalDate = fmtSpeDate(item.airItinerary.originDestinationOptions[0].flightSegments[lastSegmentIndex].arrivalDateTime);
            flightInfo.flightTime = timeBetween(item.airItinerary.originDestinationOptions[0].flightSegments[0].departureDateTime,item.airItinerary.originDestinationOptions[0].flightSegments[lastSegmentIndex].arrivalDateTime);
            flightInfo.airItinerary = item.airItinerary;
            flightInfo.priceRequestInformation = item.airItineraryPricingInfo.priceRequestInformation
            if(item.airItineraryPricingInfo.itinTotalFares[0].totalFare.currencyCode){
                flightInfo.currencyCode =item.airItineraryPricingInfo.itinTotalFares[0].totalFare.currencyCode
            }else{
                flightInfo.currencyCode =item.airItineraryPricingInfo.PTC_FareBreakdowns[0].passengerFareList[0].baseFare.currencyCode
            }
            flightInfo.totalFare =item.airItineraryPricingInfo.itinTotalFares[0].totalFare.amount;
            let ptcFareBreakdowns = item.airItineraryPricingInfo.PTC_FareBreakdowns;
            flightInfo.airItineraryRPH = item.airItinerary.airItineraryRPH
            flightInfo.cabinClass = item.airItinerary.originDestinationOptions[0].flightSegments[0].cabinClass;
            flightInfo.airLineCode = item.airItinerary.originDestinationOptions[0].flightSegments[0].marketingAirlineCode;
            flightInfo.image ="http://images.skywidetech.com/airlines/"+item.airItinerary.originDestinationOptions[0].flightSegments[0].marketingAirlineCode+".gif";
            flightInfo.flightNumber =item.airItinerary.originDestinationOptions[0].flightSegments[0].flightNumber;
            //托运行李额
            for(let j = 0; j <item.airItineraryPricingInfo.PTC_FareBreakdowns[0].passengerFareList.length;j++){
                if(item.airItineraryPricingInfo.PTC_FareBreakdowns[0].passengerFareList[j].usage=="TicketFee"){
                    if(item.airItineraryPricingInfo.PTC_FareBreakdowns[0].passengerFareList[j].fareBaggageAllowanceList){
                        if(item.airItineraryPricingInfo.PTC_FareBreakdowns[0].passengerFareList[j].fareBaggageAllowanceList.length>0 &&item.airItineraryPricingInfo.PTC_FareBreakdowns[0].passengerFareList[j].fareBaggageAllowanceList!=null){
                            flightInfo.baggage =item.airItineraryPricingInfo.PTC_FareBreakdowns[0].passengerFareList[0].fareBaggageAllowanceList[0].unitOfMeasureQuantity
                            flightInfo.baggageUnit =item.airItineraryPricingInfo.PTC_FareBreakdowns[0].passengerFareList[0].fareBaggageAllowanceList[0].unitOfMeasure
                        }else{
                            flightInfo.baggage=0;
                            flightInfo.baggageUnit="k"
                        }
                    }
                    else{
                        flightInfo.baggage=0;
                        flightInfo.baggageUnit="k"
                    }
                }
            }
            flightInfo.bookingSourceType = item.bookingSourceType
            for (let j = 0; j < ptcFareBreakdowns.length; j++) {
                let code = ptcFareBreakdowns[j].passengerTypeQuantity.code;
                if ("ADT" == code){
                    flightInfo.adultFare = ptcFareBreakdowns[j].passengerFareList[0].baseFare.amount
                    flightInfo.adultTaxes = ptcFareBreakdowns[j].passengerFareList[0].taxes.amount
                }else if ("CNN" == code){
                    flightInfo.childFare = ptcFareBreakdowns[j].passengerFareList[0].baseFare.amount
                    flightInfo.childTaxes = ptcFareBreakdowns[j].passengerFareList[0].taxes.amount
                }
            }
            state.oneWayFlightList.push(flightInfo)
        }
    },
    [types.UPDATE_MULTIPLELOWFAREFLIGHTSRS](state, payload){
        console.log(payload.multipleFareSearchRS);
        state.mutipleCities=[]
        if(payload.multipleFareSearchRS.success!=false){
            for(let i=0;i<payload.multipleFareSearchRS.pricedItineraryList.length;i++){
                let airItinerary =payload.multipleFareSearchRS.pricedItineraryList[i].airItinerary;
                let airItineraryPricingInfo = payload.multipleFareSearchRS.pricedItineraryList[i].airItineraryPricingInfo;
                let multiplePtcFareBreakdowns= airItineraryPricingInfo.PTC_FareBreakdowns;
                let multipleFlight = {
                    name:"",
                    totalFares: 0,
                    price: 0,
                    currencyTag: '',
                    personCount: 0,
                    fromCity: '',
                    fromAirport: '',
                    toCity: '',
                    toCountryCode: '',
                    airItineraryRPH: '',
                    index: '',
                    airItinerary:airItinerary,
                    bookingSourceType:"",
                    adultFare:0,
                    childFare:0,
                    adultTaxes:0,
                    childTaxes:0,
                    totalFare:0,
                    baggage:0,
                    baggageUnit:"",
                    cabinClass:'',
                    multipleFlightList:[],
                    currencyCode:"",
                    equipmentCode:''
                };
                for (let j = 0; j < multiplePtcFareBreakdowns.length; j++) {
                    let code = multiplePtcFareBreakdowns[j].passengerTypeQuantity.code;
                    if ("ADT" == code){
                        multipleFlight.adultFare = multiplePtcFareBreakdowns[j].passengerFareList[0].baseFare.amount
                        multipleFlight.adultTaxes = multiplePtcFareBreakdowns[j].passengerFareList[0].taxes.amount
                        multipleFlight.currencyCode = multiplePtcFareBreakdowns[j].passengerFareList[0].baseFare.currencyCode
                    }else if ("CNN" == code){
                        multipleFlight.childFare = multiplePtcFareBreakdowns[j].passengerFareList[0].baseFare.amount
                        multipleFlight.childTaxes = multiplePtcFareBreakdowns[j].passengerFareList[0].taxes.amount
                    }
                }
                multipleFlight.totalFare =airItineraryPricingInfo.itinTotalFares[0].totalFare.amount
                if(airItineraryPricingInfo.PTC_FareBreakdowns[0].passengerFareList[0].fareBaggageAllowanceList!=null &&
                    airItineraryPricingInfo.PTC_FareBreakdowns[0].passengerFareList[0].fareBaggageAllowanceList.length>0 )
                {
                    multipleFlight.baggage = airItineraryPricingInfo.PTC_FareBreakdowns[0].passengerFareList[0].fareBaggageAllowanceList[0].unitOfMeasureQuantity
                    multipleFlight.baggageUnit = airItineraryPricingInfo.PTC_FareBreakdowns[0].passengerFareList[0].fareBaggageAllowanceList[0].unitOfMeasure
                }else{
                    multipleFlight.baggage=0;
                    multipleFlight.baggageUnit =""
                }
                multipleFlight.cabinClass = payload.multipleFareSearchRS.pricedItineraryList[i].airItinerary.originDestinationOptions[0].flightSegments[0].cabinClass
                multipleFlight.equipmentCode = payload.multipleFareSearchRS.pricedItineraryList[i].airItinerary.originDestinationOptions[0].flightSegments[0].equipmentCode
                multipleFlight.bookingSourceType = payload.multipleFareSearchRS.pricedItineraryList[i].bookingSourceType
                multipleFlight.price = airItineraryPricingInfo.itinTotalFares[0].totalFare.amount;
                let currencyCode= multipleFlight.currencyCode
                let currencyTag=""
                if (currencyCode=="USD"){
                    currencyTag = "$";
                }else if (currencyCode=="HKD"){
                    currencyTag = "HK$";
                }else {
                    currencyTag = "HK$";
                }
                multipleFlight.currencyTag= currencyTag;
                let originDestinationOptions = airItinerary.originDestinationOptions;
                let multipleFlightList = [];
                for(let j =0;j<originDestinationOptions.length;j++){
                    let flightItem =getFlightItemInfo(originDestinationOptions[j],airItineraryPricingInfo.itinTotalFares[0].totalFare.amount,currencyTag,multipleFlight.baggage,multipleFlight.baggageUnit, multipleFlight.bookingSourceType);
                    if (j < originDestinationOptions.length-1){
                        flightItem.nextVoyageRph = originDestinationOptions[j+1].rph;
                    }
                    multipleFlightList.push(flightItem)
                }
                multipleFlight.multipleFlightList = multipleFlightList

                multipleFlight.totalFares = airItineraryPricingInfo.itinTotalFares[0].totalFare.amount;
                multipleFlight.currencyCode= airItineraryPricingInfo.itinTotalFares[0].totalFare.currencyCode
                multipleFlight.priceRequestInfo = airItineraryPricingInfo.priceRequestInformation;
                multipleFlight.airItineraryRPH = airItinerary.airItineraryRPH;
                multipleFlight.rph = payload.multipleFareSearchRS.pricedItineraryList[i].rph;
                multipleFlight.index = i;
                state.mutipleCities.push(multipleFlight);
            }
        }
    },

    [types.UPDATE_LOWFAREFLIGHTSROUNDTRIPRS](state, payload){
        console.log(payload.lowFareFlightsRoundTripRS);
        console.log("进入往返")
        state.roundTripFlightList = [];
        for(let i=0;i<payload.lowFareFlightsRoundTripRS.pricedItineraryList.length;i++){
            let airItinerary =payload.lowFareFlightsRoundTripRS.pricedItineraryList[i].airItinerary;
            let airItineraryPricingInfo = payload.lowFareFlightsRoundTripRS.pricedItineraryList[i].airItineraryPricingInfo;
            let roundTripPtcFareBreakdowns= airItineraryPricingInfo.PTC_FareBreakdowns;
            let roundTripFlightItem = {
                totalFares: 0,
                price: 0,
                currencyTag: '',
                personCount: 0,
                fromCity: '',
                fromAirport: '',
                toCity: '',
                toCountryCode: '',
                airItineraryRPH: '',
                index: '',
                airItinerary:airItinerary,
                bookingSourceType:"",
                adultFare:0,
                childFare:0,
                adultTaxes:0,
                childTaxes:0,
                totalFare:0,
                baggage:0,
                baggageUnit:"",
                cabinClass:'',
                outboundItem: {
                },
                inboundItem:{
                }
            };
            for (let j = 0; j < roundTripPtcFareBreakdowns.length; j++) {
                let code = roundTripPtcFareBreakdowns[j].passengerTypeQuantity.code;
                if ("ADT" == code){
                    roundTripFlightItem.adultFare = roundTripPtcFareBreakdowns[j].passengerFareList[0].baseFare.amount
                    roundTripFlightItem.adultTaxes = roundTripPtcFareBreakdowns[j].passengerFareList[0].taxes.amount
                }else if ("CNN" == code){
                    roundTripFlightItem.childFare = roundTripPtcFareBreakdowns[j].passengerFareList[0].baseFare.amount
                    roundTripFlightItem.childTaxes = roundTripPtcFareBreakdowns[j].passengerFareList[0].taxes.amount
                }
            }
            roundTripFlightItem.totalFare =airItineraryPricingInfo.itinTotalFares[0].totalFare.amount
            if(airItineraryPricingInfo.PTC_FareBreakdowns[0].passengerFareList[0].fareBaggageAllowanceList!=null &&
                airItineraryPricingInfo.PTC_FareBreakdowns[0].passengerFareList[0].fareBaggageAllowanceList.length>0 )
            {
                roundTripFlightItem.baggage = airItineraryPricingInfo.PTC_FareBreakdowns[0].passengerFareList[0].fareBaggageAllowanceList[0].unitOfMeasureQuantity
                roundTripFlightItem.baggageUnit = airItineraryPricingInfo.PTC_FareBreakdowns[0].passengerFareList[0].fareBaggageAllowanceList[0].unitOfMeasure
            }else{
                roundTripFlightItem.baggage=0;
                roundTripFlightItem.baggageUnit =""
            }
            roundTripFlightItem.cabinClass = payload.lowFareFlightsRoundTripRS.pricedItineraryList[i].airItinerary.originDestinationOptions[0].flightSegments[0].cabinClass
            roundTripFlightItem.bookingSourceType = payload.lowFareFlightsRoundTripRS.pricedItineraryList[i].bookingSourceType
            let originDestinationOptions = airItinerary.originDestinationOptions;
            roundTripFlightItem.outboundItem = getFlightItemInfo(originDestinationOptions[0],airItineraryPricingInfo.itinTotalFares[0].totalFare.amount,airItineraryPricingInfo.itinTotalFares[0].totalFare.currencyCode,
                roundTripFlightItem.baggage,roundTripFlightItem.baggageUnit,roundTripFlightItem.bookingSourceType,airItinerary.airItineraryRPH);
            roundTripFlightItem.inboundItem = getFlightItemInfo(originDestinationOptions[originDestinationOptions.length-1],airItineraryPricingInfo.itinTotalFares[0].totalFare.amount,airItineraryPricingInfo.itinTotalFares[0].totalFare.currencyCode,
                roundTripFlightItem.baggage,roundTripFlightItem.baggageUnit,roundTripFlightItem.bookingSourceType,airItinerary.airItineraryRPH);
            let currencyCode= airItineraryPricingInfo.itinTotalFares[0].totalFare.currencyCode
            let currencyTag=""
            if (currencyCode=="USD"){
                currencyTag = "$";
            }else if (currencyCode=="HKD"){
                currencyTag = "HK$";
            }else {
                currencyTag = "HK$";
            }
            currencyTag;
            roundTripFlightItem.currencyTag= currencyTag;
            roundTripFlightItem.totalFares = airItineraryPricingInfo.itinTotalFares[0].totalFare.amount;
            roundTripFlightItem.price = airItineraryPricingInfo.itinTotalFares[0].totalFare.amount;
            roundTripFlightItem.currencyCode= airItineraryPricingInfo.itinTotalFares[0].totalFare.currencyCode
            roundTripFlightItem.priceRequestInfo = airItineraryPricingInfo.priceRequestInformation;
            roundTripFlightItem.airItineraryRPH = airItinerary.airItineraryRPH;
            roundTripFlightItem.rph = payload.lowFareFlightsRoundTripRS.pricedItineraryList[i].rph;
            roundTripFlightItem.name = roundTripFlightItem.inboundItem.name;
            roundTripFlightItem.index = i;
            state.roundTripFlightList.push(roundTripFlightItem);
        }
    },
    [types.UPDATE_VERIFYPRICERS](state, payload){
        console.log(payload.verifyPriceRS);
        state.verifyPriceRS = payload.verifyPriceRS
    },
    [types.UPDATE_BOOKRS](state, payload){
        console.log(payload.bookRS);
        state.bookRS = payload.bookRS
    },
}
function timeBetween(startTime,endTime) {
    // 计算时间戳
    let timeRes = '';
    startTime = new Date(startTime).getTime();
    endTime = new Date(endTime).getTime();
    timeRes = (endTime - startTime)/1000; // 计算时间差,并把毫秒转换成秒
    let hours = parseInt(timeRes/3600);
    let minutes = parseInt(timeRes%3600/60); // 分钟 -(day*24) 以60秒为一整份 取余 剩下秒数 秒数/60 就是分钟数
    if (hours >= 0 && hours <=9) {
        hours = `0${hours}`;
    }
    if (minutes >= 0 && minutes <=9) {
        minutes = `0${minutes}`;
    }
    if(sessionStorage.getItem('LanguageType')=="zh-CN"||sessionStorage.getItem('LanguageType')=="zh-TW"){
        return hours +'小時'+ minutes + '分鐘'
    }
    else{
        return hours +'h '+ minutes + 'm'
    }
}

function fmtClock(dateStr) {
    let date = new Date(dateStr);
    let hours = date.getHours().toString();
    let minutes = date.getMinutes().toString();
    if (hours >= 0 && hours <= 9) {
        hours = `0${hours}`;
    }
    if (minutes >= 0 && minutes <= 9) {
        minutes = `0${minutes}`;
    }
    return hours + ':' + minutes;
}
function fmtSpeDate(dateStr) {
    let date = new Date(dateStr);
    let month = date.getMonth();
    let day = date.getDate();
    let week = date.getDay();
    let dateFormatStr = "";
        if (day > 0 && day <=9) {
            day = `0${day}`;
        }
        switch (week) {
            case 0:
                week = 'SUN, ';break;
            case 1:
                week = 'MON, ';break;
            case 2:
                week = 'TUE, ';break;
            case 3:
                week = 'WED, ';break;
            case 4:
                week = 'THU, ';break;
            case 5:
                week = 'FRI, ';break;
            case 6:
                week = 'SAT, ';break;
            default:
        }
        switch (month){
            case 0:
                dateFormatStr =  week + day + " JAN";break;
            case 1:
                dateFormatStr =  week + day + " FEB";break;
            case 2:
                dateFormatStr =  week + day + " MAR";break;
            case 3:
                dateFormatStr =  week + day + " APR";break;
            case 4:
                dateFormatStr =  week + day + " MAY";break;
            case 5:
                dateFormatStr =  week + day + " JUN";break;
            case 6:
                dateFormatStr =  week + day + " JUL";break;
            case 7:
                dateFormatStr =  week + day + " AUG";break;
            case 8:
                dateFormatStr =  week + day + " SEP";break;
            case 9:
                dateFormatStr =  week + day + " OCT";break;
            case 10:
                dateFormatStr =  week + day + " NOV";break;
            case 11:
                dateFormatStr =  week + day + " DEC";break;
            default:
        }



    return dateFormatStr;
}
function getFlightItemInfo(originDestinationOption,price,currencyCode,baggage,baggageUnit,bookingSourceType,rph) {
    let flightItem = {
        baggage:"",
        baggageUnit:"",
        currencyCode:"",
        departureDateTime: '',
        departureDate: '',
        departureTime: '',
        departureAirportCode: '',
        fromCity: '',
        fromCountryCode: '',
        stopStations:[],
        arrivalDateTime: '',
        arrivalDate: '',
        arrivalTime: '',
        image: '',
        airLineName: '',
        marketingAirlineCode: '',
        name: '',
        arrivalAirportCode: '',
        cabinClass: '',
        totalFares: 0,
        priceRequestInfo: {},
        price: 0,
        airlineCodes: [],
        stopNumber: 0,
        stopAirportCodes: '',
        flightNumber:'',
        rph:"",
        bookingSourceType:"",
        lowestPrice:"",
        equipmentCode:''
    };
    let marketingAirlineCode ;
    let airlineCodes = [];
    let airlineCodeArr = [];
    let airlineNameArr = [];
    let cabinClassArr = [];
    let segmentCount = originDestinationOption.flightSegments.length
    if (segmentCount>1){



        //第一個索引為起始點
        let flightSegments = originDestinationOption.flightSegments;
        marketingAirlineCode = flightSegments[0].marketingAirlineCode;
        flightItem.departureDateTime = flightSegments[0].departureDateTime;
        flightItem.departureDate = fmtSpeDate(flightSegments[0].departureDateTime);
        flightItem.departureTime = fmtClock(flightSegments[0].departureDateTime);
        flightItem.departureAirportCode =flightSegments[0].departureAirportCode
        flightItem.flightNumber =flightSegments[0].flightNumber;
        flightItem.equipmentCode =flightSegments[0].equipmentCode;

            flightItem.price = price
        flightItem.currencyCode = currencyCode
        // flightItem.fromCity = fromCity;
        // flightItem.fromCountryCode = fromCountryCode;
        flightItem.image = "http://images.skywidetech.com/airlines/"+marketingAirlineCode+".gif";
        flightItem.airLineName = marketingAirlineCode;
        flightItem.marketingAirlineCode = marketingAirlineCode;
        flightItem.name = flightItem.airLineName;
        flightItem.cabinClass = flightSegments[0].cabinClass;
        airlineCodes.push(marketingAirlineCode);
        airlineCodeArr.push(marketingAirlineCode);
        //記錄中間停靠站航空公司信息
        for (let j = 1; j < flightSegments.length-1; j++) {
            let flightSegment = flightSegments[j];
            flightItem.stopAirportCodes += flightSegment.departureAirportCode;
            if (j<flightSegments.length-1){
                flightItem.stopAirportCodes += ",";
            }
            airlineCodes.push(flightSegment.marketingAirlineCode);
            airlineCodeArr.push(flightSegment.marketingAirlineCode);
        }
        //以最後一個索引為終止點
        flightItem.arrivalDateTime = flightSegments[flightSegments.length-1].arrivalDateTime;
        flightItem.arrivalDate = fmtSpeDate(flightSegments[flightSegments.length-1].arrivalDateTime);
        flightItem.arrivalTime = fmtClock(flightSegments[flightSegments.length-1].arrivalDateTime);
        flightItem.arrivalAirportCode = flightSegments[flightSegments.length-1].arrivalAirportCode;
        flightItem.rph = originDestinationOption.flightSegments[flightSegments.length-1].rph;
        flightItem.stopNumber=flightSegments.length-1;
        airlineCodes.push(flightSegments[flightSegments.length-1].marketingAirlineCode);
        airlineCodeArr.push(flightSegments[flightSegments.length-1].marketingAirlineCode);
        airlineCodes = unique(airlineCodes);
        if (airlineCodes.length>1){
            flightItem.name='';
            for (let j = 0; j < airlineCodes.length; j++) {
                let airlineCode = airlineCodes[j];
                let airlineName = airlineCode
                airlineNameArr.push(airlineName);
                flightItem.name += airlineName;
                if (j<airlineCodes.length-1){
                    flightItem.name += ",\n";
                }
            }
        }
        //存入中转站信息
        for(let i=0;i<flightSegments.length;i++){
            let stopItem=flightSegments[i]
            let stopInfo = {
                departureDateTime:"",
                departureAirPort:"",
                arrivalAirPort:"",
                departureTime:"",
                arrivalTime:"",
                departureDate:"",
                arrivalDate:"",
                preRph:'',
                flightTime:"",
                totalFare:"",
                cabinClass:"",
                airItineraryRPH:"",
                numberInParty:"",
                flightNumber:"",
                bookingSourceType:"",
                image:"",
            }
            stopInfo.preRph = rph
            stopInfo.departureDateTime =stopItem.departureDateTime
            stopInfo.arrivalDateTime =stopItem.arrivalDateTime
            stopInfo.departureAirPort =stopItem.departureAirportCode
            stopInfo.arrivalAirPort =stopItem.arrivalAirportCode
            stopInfo.departureTime =fmtClock(stopItem.departureDateTime)
            stopInfo.arrivalTime =fmtClock(stopItem.arrivalDateTime)
            stopInfo.departureDate = fmtSpeDate(stopItem.departureDateTime)
            stopInfo.arrivalDate = fmtSpeDate(stopItem.arrivalDateTime)
            stopInfo.flightTime = timeBetween(stopItem.departureDateTime,stopItem.arrivalDateTime);
            stopInfo.flightNumber =stopItem.flightNumber;
            stopInfo.airLineCode = stopItem.marketingAirlineCode;
            stopInfo.bookingSourceType = bookingSourceType
            stopInfo.image ="http://images.skywidetech.com/airlines/"+stopItem.marketingAirlineCode+".gif";
            stopInfo.cabinClass = stopItem.cabinClass;
            stopInfo.airItineraryRPH = stopItem.rph
            stopInfo.numberInParty = stopItem.numberInParty
            flightItem.stopStations.push(stopInfo)
        }
        flightItem.airlineCodes = unique(airlineCodes);
    }
    else if (segmentCount==1){
        //first departure
        marketingAirlineCode = originDestinationOption.flightSegments[0].marketingAirlineCode;
        flightItem.departureDateTime = originDestinationOption.flightSegments[0].departureDateTime;
        flightItem.departureDate = fmtSpeDate(originDestinationOption.flightSegments[0].departureDateTime);
        flightItem.departureTime = fmtClock(originDestinationOption.flightSegments[0].departureDateTime);
        flightItem.departureAirportCode = originDestinationOption.flightSegments[0].departureAirportCode
        flightItem.flightNumber = originDestinationOption.flightSegments[0].flightNumber;
        flightItem.equipmentCode = originDestinationOption.flightSegments[0].equipmentCode;
        flightItem.currencyCode = currencyCode
        // flightItem.fromCity = fromCity;
        // flightItem.fromCountryCode = fromCountryCode;
        flightItem.image = "http://images.skywidetech.com/airlines/"+marketingAirlineCode+".gif";
        flightItem.airLineName =marketingAirlineCode
        flightItem.marketingAirlineCode = marketingAirlineCode;
        flightItem.name = flightItem.airLineName;
        flightItem.cabinClass = originDestinationOption.flightSegments[0].cabinClass;
        flightItem.price = price
        //last arrival
        flightItem.arrivalDateTime = originDestinationOption.flightSegments[0].arrivalDateTime;
        flightItem.arrivalDate = fmtSpeDate(originDestinationOption.flightSegments[0].arrivalDateTime);
        flightItem.arrivalTime = fmtClock(originDestinationOption.flightSegments[0].arrivalDateTime);
        flightItem.arrivalAirportCode = originDestinationOption.flightSegments[0].arrivalAirportCode
        flightItem.stopNumber=0;
        flightItem.rph = originDestinationOption.flightSegments[0].rph
        let  airlineCodes = [];
        airlineCodes.push(marketingAirlineCode);
        flightItem.airlineCodes = airlineCodes;
    }
    flightItem.flightTime = timeBetween(flightItem.departureDateTime, flightItem.arrivalDateTime);
    flightItem.baggage = baggage
    flightItem.baggageUnit = baggageUnit;
    flightItem.bookingSourceType = bookingSourceType;
    flightItem.voyageRph = originDestinationOption.rph;
    airlineNameArr.push(flightItem.airLineName);
    airlineCodeArr.push(marketingAirlineCode);
    cabinClassArr.push(flightItem.cabinClass);
    return flightItem;
}
function unique (arr) {
    return Array.from(new Set(arr))
}


export default mutations;