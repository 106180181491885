import Dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
Dayjs.extend(utc);
Dayjs.extend(tz);
Dayjs.tz.setDefault("Asia/Shanghai")

// 使用dayjs格式化日期对象
function formatDate(value,formatStr){
    if(!formatStr){
        formatStr = 'YYYY-MM-DD';
    }
    return Dayjs(value).format(formatStr);
}

function add(date,num, unit, formatStr) {
    if(!formatStr){
        formatStr = 'YYYY-MM-DD';
    }
    return Dayjs(date).add(num, unit).format(formatStr);
}

function format(date) {
    let fullYear = date.getFullYear();
    let month = date.getMonth()+1;
    let day = date.getDate();

    let dayStr = "";
    if (day<10){
        dayStr = "0"+day;
    }else {
        dayStr = day;
    }

    let monStr = "";
    if (month<10){
        monStr = "0"+month;
    }else {
        monStr = month;
    }

    let dateStr = fullYear+"-"+monStr+"-"+dayStr;
    return dateStr;
}

function getDefaultDateByPreDate(preDate) {
    let defaultDate = new Date(preDate.getFullYear(),preDate.getMonth(), preDate.getDate()+1,
        preDate.getHours(), preDate.getMinutes(), preDate.getSeconds(), preDate.getMilliseconds());
    return format(defaultDate);
}

function validateDefaultDate(date) {
    let nowDate = Date.now();
    let defaultDate = new Date(date.getFullYear(),date.getMonth(), date.getDate(),
        date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    if (nowDate > date){
        return format(nowDate);
    }
    return format(defaultDate);
}

/*
* 年份
* */
function getDurationByDates(startDate, endDate) {
    let startYear=startDate.getFullYear();
    let startMonth = startDate.getMonth();

     let endYear=endDate.getFullYear();
    let endMonth = endDate.getMonth();

    let yearsDec = endYear - startYear;
    if (endMonth>startMonth)yearsDec--;
    return yearsDec;
}

function getDayDurationByDates(startDate, endDate) {
    return parseInt(Math.abs(new Date(endDate) - new Date(startDate))/1000/60/60/24);
}

function dateShortcuts(){
    return [
        {
            text: 'Next week',
            value: () => {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() + 3600 * 1000 * 24 * 7)
                return [end, start]
            },
        },
        {
            text: 'Next month',
            value: () => {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() + 3600 * 1000 * 24 * 30)
                return [end, start]
            },
        },
        {
            text: 'Next 3 months',
            value: () => {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() + 3600 * 1000 * 24 * 90)
                return [end, start]
            },
        },
    ]
}

function lastDateShortcuts(){
    return [
        {
            text: 'Last week',
            value: () => {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                return [start, end]
            },
        },
        {
            text: 'Last month',
            value: () => {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                return [start, end]
            },
        },
        {
            text: 'Last 3 months',
            value: () => {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                return [start, end]
            },
        },
    ]
}

function validateCheckDate(rule, value, callback){
    if(!value[0] && !value[1]){
        callback(new Error('Please enter check date.'));
    }else{
        callback()
    }
}

function getLastYear(m){
    if(parseInt(m)>0){//m是 往前推的总月份
        let myDate = new Date();
        // var mytime=formatDate('yyyy-MM-dd');//获取当前日期
        let year = myDate.getFullYear();//获取当前年
        let month = myDate.getMonth()+1;//获取当前月
        let day = myDate.getDate();//获取当前日
        let lowData='';//当前年月日往前推m个月后获取到的年月日
        let ylow=parseInt(parseInt(m)/12);//往前推的总月份换成对应的年数取整
        let mlow=parseInt(m)%12;//往前推的月数
        if(ylow>0){
            year-=ylow;//年要再减一
        }
        if((mlow>month) || (mlow==month)){//往前推的月份大于或等于当前月份，12减去往前推的月份加上现在的月份
            year=year-1;
            month=12-mlow+month;
            lowData=year+'/0'+month+'/'+day
        }else{//往前推的月份小于当前月份
            month-=mlow;
            lowData=year+'/0'+month+'/'+day
        }
        return new Date(lowData).getTime();
    }
}

function getDiffDays (dateTimeAfter, dateTime) {
    let diff = Dayjs(Dayjs(dateTimeAfter).format("YYYY-MM-DD")).diff(Dayjs(dateTime).format("YYYY-MM-DD"), "day")
    return diff
}

//不超过24小时不算一天
function getDuration(dateTimeAfter, dateTime, type) {
    let diff
    if(dateTimeAfter == ""){
        diff = Dayjs().diff(dateTime, type)
    }else if(dateTime == ""){
        diff = Dayjs(dateTimeAfter).diff(Dayjs(), type)
    }else{
        diff = Dayjs(dateTimeAfter).diff(dateTime, type)
    }
        
    return diff
}

function getExpireInMinute(dateTimeAfter) {
    let diff = 0
    diff = Dayjs(dateTimeAfter).diff(Dayjs(), "minute")
    return diff
}

function getTrainDuration (arrivalDateTime, departureDateTime) {
    let diff = getDuration(arrivalDateTime, departureDateTime, "minute")
    let hourStr = Math.floor(diff/60) + "h" 
    let minuteStr = diff%60 + "m"
    return hourStr + " " + minuteStr   
}

function isBefore(firstDateTime, secondDateTime){
    return Dayjs(firstDateTime).isBefore(secondDateTime)
}

export default {
    formatDate,
    validateDefaultDate,
    add,
    format,
    getDefaultDateByPreDate,
    getDurationByDates,
    getDayDurationByDates,
    dateShortcuts,
    lastDateShortcuts,
    validateCheckDate,
    getLastYear,
    getDuration,
    getExpireInMinute,
    getTrainDuration,
    getDiffDays,
    isBefore,
}
