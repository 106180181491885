export const UPDATE_SEARCH_CRITERIA = "updateSearchCriteria"
export const UPDATE_TRAIN_TICKETS_RS = "updateTrainTicketsRS"

export const UPDATE_TRAIN_TICKETS = "updateTrainTickets";
export const UPDATE_RAIL_PASSES_RS = "updateRailPassesRS"
export const UPDATE_RAIL_PASSES = "updateRailPasses"

export const UPDATE_TRAIN_TICKET_RESULTS = "updateTrainTicketResults"
export const UPDATE_RAIL_PASS_RESULTS = "updateRailPassResults"

export const UPDATE_SELECTED_TICKET = "updateSelectedTicket"
export const UPDATE_TICKET_TICKET = "updateTiketDetails"
export const UPDATE_SELECTED_PASS = "updateSelectedPass"
export const UPDATE_SELECTED_VALIDITY = "updateSelectedValidity"
export const UPDATE_VALIDITY = "updateValidity"

export const UPDATE_CART = "updateCart"
export const UPDATE_CART_NUMBER = "updateCartNumber"

export const UPDATE_BOOK_TICKET_RS ="updateBookTicketRS"
export const UPDATE_BOOKE_FROM_CART ="updateBookFromCart"
export const UPDATE_TICKET_BOOKED = "updateTicketBooked"