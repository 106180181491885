import axios from "../utils/axiosUtils"

export default {

    getBookingList(bookingHistoryRQ){
        let url = "/api/v1/booking/history"
        console.info(bookingHistoryRQ);
        return axios.post(url, bookingHistoryRQ, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    },

    getUserInfoApi(request){
        //保证对象是Json字符串
        if (typeof request === 'object'){
            request = JSON.stringify(request);
        }
        console.info(request);
        return axios.post("/api/v1/account/user-info", request,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    },

    getBookingDetails(request){
        return axios.post("/api/v1/rail/resretrieve-details", request,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        })    
    },

    getBookingInfo(request){
        //保证对象是Json字符串
        return axios.post("/api/v1/booking/history", request,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    },

    cancelBookingApi(request){
        console.info(request);
        return axios.post("/api/v1/booking/cancel", request,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    },

    addGoodsToCart(request){
        console.info(request);
        return axios.post("/api/v1/bookings/shopping-cart", request,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    },

    removeGoodsFromCart(indexes){
        console.info(indexes);
        return axios.delete("/api/v1/bookings/shopping-cart",{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            },
            data: indexes
        });
    },
    getGoodsFromCart(){
        return axios.get("/api/v1/bookings/shopping-cart",{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    },

    // .POST("/shopping-cart", bookingHandler::addGoodsToCart)
    //     .DELETE("/shopping-cart", bookingHandler::removeGoodsFromCart)
    //     .GET("/shopping-cart", bookingHandler::getGoodsFromCart)
}