/**
 *  根据mutationTypes中常量定义的方法名来构造指定命名的函数，函数实现state中数据的状态转变
 * */
import * as types from "./mutationsTypes"
import RailUtils from "@/utils/RailUtils";
const mutations = {
    [types.UPDATE_BOOKING_HISTORY_RS](state, payload){
        state.bookingHistoryRS = payload.bookingHistoryRS
    },
    
    [types.UPDATE_USERINFO](state, payload){
        state.userInfo = payload
    },

    [types.UPDATE_CANCELRS](state, payload){
        state.cancelRS = payload
    },

    [types.UPDATE_SELECTED_BOOKING] (state, payload) {
        state.selectedBooking = payload.selectedBooking
    },

    [types.UPDATE_BOOKING_DETAILS_RS] (state, payload) {
        state.bookingDetailsRS = payload.bookingDetailsRS
    },

    [types.UPDATE_BOOKING_DETAILS] (state, payload) {
        state.bookingDetails = payload.bookingDetails
    },
    [types.UPDATE_SHOPPING_CART] (state, payload) {
        state.shoppingCart = RailUtils.getCartStatus(payload.shoppingCart);
        state.cartNumber = payload.shoppingCart.length;
    }
}

export default mutations;