import axios from "../utils/axiosUtils"
const activityUrl = "/api/v1/touractivity";
export default {

    searchActivities(request){
        //保证对象是Json字符串
        if (typeof request === 'object'){
            request = JSON.stringify(request);
        }
        return axios.post(activityUrl+"/search", request,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    },

    availActivity(request){
        if (typeof request === 'object'){
            request = JSON.stringify(request);
        }
        return axios.post(activityUrl+"/avail", request,{
            headers: {
                'Content-Type': 'application/json'
            }
        });

    },

    getActivityDestinationAutocomplete(key){
        return axios.get("/api/v1/autocomplete/hotel-destination?language=en&cityOnly=true&keyword="+key);
    }
}