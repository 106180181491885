import axios from "../utils/axiosUtils"
const airUrl = "/api/v1/air";

export default {
    lowFareSearch(lowFareSearchRQ) {
        //保证对象是Json字符串
        if (typeof lowFareSearchRQ === 'object'){
            lowFareSearchRQ = JSON.stringify(lowFareSearchRQ);
        }
        return axios.post(airUrl+"/low-fare-search", lowFareSearchRQ,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    },
    verifySearch(verifySearchRQ)  {
        //保证对象是Json字符串
        if (typeof verifySearchRQ === 'object'){
            verifySearchRQ = JSON.stringify(verifySearchRQ);
        }
        return axios.post(airUrl+"/verify-price", verifySearchRQ,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    },
    book(bookRQ)  {
        //保证对象是Json字符串
        if (typeof bookRQ === 'object'){
            bookRQ = JSON.stringify(bookRQ);
        }
        return axios.post(airUrl+"/book", bookRQ,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    },

}