/*
* 代替视图，与mutation进行交互，根据用户需求通过hotelApi工具包请求后端数据，通过mutations更新state中的状态
* */
import * as types from "./mutationsTypes"
import hotelApi from "../../../api/hotelApi";

const actions = {
    searchAvailHotels({commit}, payload){
        hotelApi.searchAvailHotels(payload.hotelAvailRQ).then(res =>{
            let availRS = "";
            if (res.data.roomStays!=undefined && res.data.roomStays.roomStayList !=undefined && res.data.roomStays.roomStayList.length >0){
                availRS = res.data;
            }
            commit(types.UPDATE_AVAILRS, {
                availRS: availRS,
            });
        });
    },

    searchSelectedHotel({commit}, payload){
        //todo 搜索酒店价格信息, /avail接口
        hotelApi.searchAvailRooms(payload.availRQ).then(res=>{
            console.log(res.data);
            //todo change
            commit(types.UPDATE_AVAILROOMRS,res.data)
        })
    },

    searchSelectedHotelDescription({commit}, payload){
        //todo 搜索酒店描述信息, /description接口
        hotelApi.searchHotelDescription(payload.hotelDescriptionRQ).then(res => {
            // console.log(res.data);
            //todo change
            commit(types.UPDATE_HOTELDESCRIPTION,res.data)
        })
    },

    confirmRoom({commit}, payload){
        hotelApi.confirmInformation(payload.confirmRQ).then(res =>{
            console.log(res)
            let confirmaRS = "";
            confirmaRS = res.data;

            commit(types.UPDATE_CONFIRMRS, {
               confirmaRS: confirmaRS,
            });
        });
    },


    hotelPreBook({commit}, payload){
        hotelApi.preBookHotelRoom(payload.preBookRQ).then(res =>{
            let preBookRS = "";
            preBookRS = res.data;

            commit(types.UPDATE_PREBOOKRS, {
                preBookRS: preBookRS,
            });
        });
    },

    hotelB2BBook({commit}, payload){
        hotelApi.B2BBook(payload.B2BRQ).then(res =>{
            let B2BBookRS = "";
            B2BBookRS = res.data;

            commit(types.UPDATE_B2BBOOKRS, {
                B2BBookRS: B2BBookRS,
            });
        });
    },


}

export default actions;